.folder {
  width: 420px;
  height: 310px;
  margin: 10em auto 10em;

  border-radius: 5px 25px 25px 25px;
  filter: drop-shadow(0 0 0.75rem grey);

  background: white;
  position: relative;
}

.folder::before {
  content: "";
  position: absolute;
  top: -18px;
  width: 200px;
  height: 25px;
  background: white;
  border-radius: 25px 0 0 0;
  clip-path: path("M 0 0 L 160 0 C 185 2, 175 16, 200 18 L 0 50 z");
}

.folder::after {
  content: "";
  position: absolute;
  left: 40px;
  width: 85px;
  height: 5px;
  top: -18px;
  background: #7036e9;
  border-radius: 0 0 5px 5px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: table-row;
  align-items: center;
  // margin: 10px;
  border-radius: 6px;
  cursor: pointer;
  min-height: 50px;
}

.folder_icon {
  margin: 0px 10px;
  display: block;
  // width: 1.5rem;
}

.folder_icon img {
  width: 1.5rem;
  height: auto;
  object-fit: cover;
}

.menu_icon {
  // margin-left: auto;
  display: flex;
  column-gap: 5px;
  padding: 5px;
  align-items: center;
  justify-content: flex-end;
  // svg {
  //   color: var(--Blue-color);
  // }
}

.card:hover {
  box-shadow: 0 2px 15px 0 rgba(173, 216, 230, 60%);
  filter: brightness(0.8);
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}

.folder-table {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  table-layout: fixed;
}
.folder-cell {
  display: table-cell;
  overflow-wrap: anywhere;
  padding: 5px;
  vertical-align: middle;
}
#doc_name {
  width: 40%;
}
// #doc_date {
//   width: 30%;
// }
// #doc_type {
//   width: 20%;
// }
// #doc_size {
//   width: 10%;
//   // text-align: end;
// }
