.login_container {
    width: 100vw;
    height: 100vh;
  }
  .login_block {
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.103);
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(1.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: 400px;
    padding: 50px 10px;
  }
  .logo {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .logo img {
    width: 100%;
    height: fit-content;
  }
  .signin_info {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    flex-wrap: wrap;
  }
  .welcome {
    font-size: 1.5rem;
    margin-bottom: 0px;
    color:#525252;
  }
  .input_block {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    color: #0c0c0c;
    
  }
  label {
    padding: 10px 0;
    font-size: 1.2rem;
    font-weight: normal;
    color: #0c0c0c;
  }
  .error_text {
    color: red;
    font-size: 1rem;
  }
  .input_section{
      width: 300px;
      height: 30px;
      border: 1px solid #1C74BC;
      border-radius: 5px;
      padding-left: 5px;
  }
  .signin_button{
      width: 100%;
      color: #fff;
      background-color: #1C74BC;
      padding: 8px;
      margin: 20px 0 ;
      font-size: medium;
      font-weight: bold;
      border: 2px solid #1C74BC;
      border-radius: 10px;
      cursor: pointer;
  }
  .signin_button:hover {
    background-color: #27AAE0;
    border: 2px solid #27AAE0;
  }
  .forgotpassword {
    text-align: end;
    text-decoration: none;
    color: #1C74BC;
    font-size: 1rem;
    padding-top: 10px;
  }
  .alter_text {
    margin-top: -6px;
    font-size: 0.9rem;
    color: gray;
  }
  .signup_link{
      font-size: 1rem;
      color: #1C74BC;
      text-decoration: none;
      font-weight: bolder;
  }
  .signup_link:hover{
    color:#27AAE0;
  }
  .google_login {
    width: 250px;
    margin-top: 20px;
    padding: 7px 20px;
    border: 2px solid rgb(173 173 173 / 88%);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #525252;
    font-size: 15px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 10%);
  }
  .google_icon {
    width: 30px;
    height: 30px;
  }
  
  .align_row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .align_column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .errormsg {
    color: #fff;
    background-color: red;
    width: 100%;
    text-align: center;
    padding: 5px;
  }
  .otp-switch{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
  .otp-link{
    color: #1C74BC;
    font-weight: 600;
    cursor: pointer;
  }
  .input_section:disabled {
    background-color: #e0dbdba1 !important;
  }
  .SignUptext{
    font-weight: bold;
    font-size:1.5rem;
    color: #525252;
    margin-bottom: 0;
} 
.password-input-container {
  position: relative;
}

.password-wrapper {
  position: relative;
  width: 100%;
}

.password-input {
  width: 100%;
  padding-right: 20px; /* Add padding to make space for the icon */
}

.password-toggle-icon {
  position: absolute;
  top: 60%;
  right: 10px; /* Position the icon inside the text box */
  transform: translateY(-50%);
  cursor: pointer;
  color: #666; /* Adjust the color if needed */
}
