.btn-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0.8rem 0px;
  align-items: flex-end;
  flex-wrap: wrap;
}
.subheading {
  font-size: 1.5em;
  font-weight: 400;
  color: #f27309;
  // padding-bottom: 10px;
}
.add_folder_modal {
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  align-items: center;
}
.ant-select-dropdown {
  font-size: 18px !important;
  color: black !important;
}
.add_folder_modal button {
  max-width: 64px;
}

.client-select-container {
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust spacing between the Labelbox and the div */  
}

.selectclient { 
  min-width: 30rem;
}
.client-select-container .labelbox select {
  font-size: 20px !important; /* Adjust font size for readability */
  padding: 8px; /* Adds some padding for better appearance */
  height: auto; /* Ensures it adjusts for the content */
}

.selected-client {
  font-size: 20px !important;  /* Adjust text size */
  font-weight: bold !important;  /* Make text bold */
  color: #f27309 !important;  /* Text color */
  position: absolute !important;  /* Position relative to parent */
  top: 0px !important;  /* Align with the top of parent */
  right: 10px !important;  /* Align to the right within parent */
  z-index: 1000 !important;  /* Ensures the text stays above other elements */
}


.toolCounts {
  color: "#2C992D";
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_icon img {
  width: 1.3rem;
  filter: brightness(0) invert(1);
}
.btn-wrapper-left {
  display: flex;
  align-items: end;
  column-gap: 0.7rem;
  flex-wrap: wrap;
  .formdiv .selectbox {
    min-width: 12rem;
  }
}
.btn-wrapper button {
  max-height: 2rem;
  margin-bottom: 4px;
}
.collapsible {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  margin: 10px 0;
}

.collapsible .arrow {
  transition: transform 0.3s ease;
  margin: 0 10px;
}

.collapsible .arrow.up {
  transform: rotate(180deg);
}

.downloaded-files {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.upload-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.drop-area {
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  width: 1000px;
  height: 300px;
}

.file-input-label {
  display: block;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}

.add-files-button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.file-list {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.remove-file {
  background: none;
  border: none;
  color: #f27309;
  cursor: pointer;
  font-size: 18px;
}

.email-switch {
  margin-top: 10px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}