/*.signup_container{
     margin-top: 130px;
    width: 100vw;
    height: 100vh; 
}
*/
.signup_block{
    background: rgba( 255, 255, 255, 0.05 );
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.103);
    backdrop-filter: blur( 1.5px );
    -webkit-backdrop-filter: blur( 1.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    max-width: 400px;
    width: 100%;
    padding: 10px;
}
.logo{
    width: 180px;
    height: 30px;
    text-align: center;
}
.logo img {
    width: 100%;
    height: 3rem;
}
.signup_info {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    flex-wrap: wrap;
}
.welcome{
    font-size: 1.3rem;
    margin-bottom: 0px;
}
.input_block{
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}
label{
    padding: 10px 0;
    font-size: larger;
    font-weight: normal;
}
.error_text {
    color: red;
    font-size: 1rem;
}
input{
    width: 300px;
    height: 30px;
    border: 1px solid #1C74BC;
    border-radius: 5px;
    padding-left: 5px;
}
.signup_button{
    width: 60%;
    color: #fff;
    background-color: #0099ff;
    padding: 8px;
    margin-top: 10px;
    font-size: medium;
    font-weight: bold;
    border: 2px solid #0099ff;
    border-radius: 10px;
}
.forgotpassword{
    text-align: end;
    text-decoration: none;
    color: gray;
    font-size: 0.9rem;
}
.alter_text{
    margin-top: -6px;
    font-size: 1.2rem;
    color: black;
}
.signup_link{
    font-size: 1rem;
    color: #1029AD;
    text-decoration: none;
    font-weight: bolder;
}
.google_login{
    width: 250px;
    margin-top: 20px;
    padding: 7px 20px;
    border: 2px solid rgba(241, 238, 238, 0.879);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: gray;
    font-size: 15px;
    cursor: pointer;
}
.google_icon{
    width: 30px;
    height: 30px;
}


.align_row{
    display: flex;
    align-items: center;
    justify-content: center;
}
.align_column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.errormsg {
    color: #fff;
    background-color: red;
    width: 100%;
    text-align: center;
    padding: 5px;
}

.StateInput{
    width: 102%;
    height: 40px;
    border-radius: 5px;
    margin-top: 5px;
}
.StateLabel{
    margin-top: 10px;
    
}
.SignUptext{
    font-weight: bold;
    font-size:1.3rem;
    color: #525252;
    margin-bottom: 0;
}
.signup-form{
    width: 100%;
    padding: 0px 2rem;
    
}