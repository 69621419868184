.offer-card {
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.offer-title {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: var(--Warning-color);
}

.offer-desc {
  text-align: justify;
}

.offer-icon {
  width: 4rem;
  height: 4rem;
  img {
    filter: invert(18%) sepia(20%) saturate(2100%) hue-rotate(170deg)
      brightness(98%) contrast(88%);
  }
}

.offer-paper {
  box-shadow: 0px 2px 1px -1px #98c2f1, 0px 1px 1px 0px rgba(28, 41, 199, 0%),
    0px 1px 3px 0px #bdc7ec !important;
  height: 100%;
}
