.side-nav {
    width: 110px;
}

.body-container {
    width: calc(100vw - 110px);
    height: 100vh;
}

.finlo-layout {
    width: 100vw;
    height: 100vh;
    display: flex;
}

.body-container main {
    height: calc(100vh - calc(57px + 1rem));
    overflow-y: auto;
    padding: 1.2em;
    overflow-x: hidden;
}

.finlo-appbar {
    height: 57px;
    background-color: #fff;
    display: grid;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 1rem;
    grid-template-columns: auto auto auto auto auto;
    column-gap: 1rem;
    box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
}

/*user profile image with first letter of first name*/

.avatar-circle {
    width: 30px;
    height: 45px;
    border-radius: 50%;
    background-color: #1355ad; /* Set a background color */
    color: #fff; /* Set the text color */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px; /* Adjust font size */
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .avatar-circle:hover {
    transform: scale(1.1); /* Slight zoom on hover */
  }
  
  
.login-user {
    color: #000;
    font-weight: 300;
    font-size: 1.3em;
    white-space: nowrap;
}

.user-avatar {
    width: 3rem;
}

.user-avatar img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.logout-icon {
    color: var(--Primary-color);
}

.logout-icon svg {
    font-size: 1.5rem !important;
}

.footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0.8rem;
}

.footer-wrapper img {
    width: 1rem;
    margin-right: 0.3em;
}

.change-password{
    width: 2rem;
}

.change-password img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(21%) sepia(70%) saturate(6198%) hue-rotate(194deg) brightness(94%) contrast(101%);
}