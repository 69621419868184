.silver-framebox .silver-fieldset {
  background: -webkit-linear-gradient(
    -57deg,
    #d1d1d1,
    #eeeeee 16%,
    #f1eded 21%,
    #ece5e5 11%,
    #d1c8c86e 27%,
    #c2bfbf 73%,
    #ffffff 54%,
    #dedede 60%,
    #dedede 73%,
    #e1dbdb 80%,
    #dedede 84%,
    #adadad
  );
  background: -o-linear-gradient(
    -57deg,
    #d1d1d1,
    #eeeeee 16%,
    #f1eded 21%,
    #ece5e5 11%,
    #d1c8c86e 27%,
    #c2bfbf 73%,
    #ffffff 54%,
    #dedede 60%,
    #dedede 73%,
    #e1dbdb 80%,
    #dedede 84%,
    #adadad
  );
  background: linear-gradient(
    -57deg,
    #d1d1d1,
    #eeeeee 16%,
    #f1eded 21%,
    #ece5e5 11%,
    #d1c8c86e 27%,
    #c2bfbf 73%,
    #ffffff 54%,
    #dedede 60%,
    #dedede 73%,
    #e1dbdb 80%,
    #dedede 84%,
    #adadad
  );
  padding: 8px !important;
  border-radius: 6px;
  position: relative;
}

.silver-framebox legend {
  width: auto;
  margin: 0;
  background: #fff;
  display: inline;
  position: absolute;
  top: 0;
  padding: 0px 0.6rem !important;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--Text-color);
  line-height: 10px;
}
.smaller-legend {
  font-size: 1rem !important;
}
.frame-wrapper {
  background: white;
  // padding: 1.3em;
  border: 1.3em solid white;
}
.transparent {
  background: transparent !important;
}
