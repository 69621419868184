.credential_card {
  height: auto;
  padding: 1rem;
  max-width: 500px;
  width: 100%;
}
.credential_title {
  justify-content: flex-start;
  column-gap: 0;
  width: 100%;
  h2 {
    color: var(--Blue-color);
  }
}
.input-warpper {
  width: 100%;
  padding: 1rem 2rem;
}
.action_btn {
  margin-top: 2rem;
}
