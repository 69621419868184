.MuiMenu-paper {
    max-width: none !important; /* Remove fixed width limits */
    min-width: 300px !important; /* Minimum width to fit text */
  }
  
  .MuiMenuItem-root {
    white-space: normal !important; /* Allow wrapping of text */
    word-break: break-word; /* Break long words */
    padding: 8px 16px; /* Add padding for better spacing */
  }
  .table-cell {
    white-space: normal !important;
    word-break: break-word !important;
  }
  