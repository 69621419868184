.dollor_card {
  width: fit-content;
  border-radius: 30px;
  display: flex;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: aliceblue;
  margin: auto;
}
.dollor-img {
  width: 9rem;
  height: 9rem;
  // padding: 1.2rem 1.2rem 0px 0px;
}
.dollor_card img {
  width: 100%;
  height: auto;
  // filter: invert(18%) sepia(20%) saturate(2100%) hue-rotate(170deg)
  //   brightness(98%) contrast(88%);
}
.dollor_card .toolBox {
  padding: 2rem 1.2rem 1.2rem 1rem;
  background-color: transparent;
}

.dollor_card .toolBox .toolDescription {
  color: black;
  font-weight: 800;
  font-size: 1rem;
}
.dollor_card .toolBox tr td {
  color: var(--Text-color);
  font-weight: bolder;
}
.grid-padding {
  padding: 0rem 0.2rem;
}
.contract-padding {
  padding: 0px 0.5rem 0.5rem 0.5rem;
}
.flex {
  display: flex;
}
.input-box-label {
  height: 38px;
  display: inline-flex;
  align-items: center;
}
