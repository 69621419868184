.support-info fieldset {
  height: 100%;
}
.support-info .silver-framebox {
  height: 100%;
}
.support-info .frame-wrapper {
  height: 100%;
  display: grid;
}
.terms {
  font-size: smaller;
}
.terms span {
  color: var(--Primary-color);
}
.paddingTop {
  padding-top: 1rem !important;
}
.flex-center {
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}
