.finlo-navbar ul li {
  list-style: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  margin-bottom: 0.5em;
}

.finlo-navbar {
  width: 100%;
  height: 100%;
  background-color: var(--Text-color);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.finlo-navbar ul {
  scrollbar-width: none;
}

.finlo-navbar ul {
  margin-left: 1.5em;
  max-height: 80vh;
  overflow-y: auto;
}

.finlo-navbar ul::-webkit-scrollbar {
  width: 0;
  display: none;
}

.finlo-navbar span {
  color: white;
  background: var(--Warning-color);
  border-radius: 15px;
  white-space: nowrap;
  padding: 0px 0.6rem;
  opacity: 0%;
  position: absolute;
  z-index: 99;
  top: -15px;
  left: 36px;
  pointer-events: none;
}

.finlo-navbar ul li.active::before {
  content: "";
  position: absolute;
  top: -40px;
  right: 0px;
  width: 40px;
  height: 40px;
  background: transparent;
  border-radius: 36%;
  box-shadow: 15px 15px 0px white;
}

.finlo-navbar ul li.active::after {
  content: "";
  position: absolute;
  bottom: -40px;
  right: 0px;
  width: 40px;
  height: 40px;
  background: transparent;
  border-radius: 35%;
  box-shadow: 15px -15px 0px white;
}

ul li.active {
  background: white;
}

.finlo-navbar ul li:hover {
  span {
    opacity: 1;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    pointer-events: none;
  }
}

.finlo-navbar .admin-nav-icon {
  width: 2.5rem;
  filter: brightness(0) invert(1);
}

.finlo-navbar .client-nav-icon {
  width: 2rem;
  filter: brightness(0) invert(1);
}

ul li.active img {
  /* filter: invert(71%) sepia(93%) saturate(1913%) hue-rotate(
   325deg) brightness(101%) contrast(87%); */
  filter: invert(55%) sepia(110%) saturate(370%) hue-rotate(334deg)
    brightness(100%) contrast(87%) !important;
}

ul li.active:hover img {
  /* filter: invert(71%) sepia(93%) saturate(1913%) hue-rotate(
  325deg) brightness(101%) contrast(87%); */
  filter: invert(55%) sepia(110%) saturate(370%) hue-rotate(334deg)
    brightness(100%) contrast(87%) !important;
}

.finlo-logo {
  background-color: #fff;
  display: inline-flex;
  margin-bottom: 2rem;
  padding: 15px 6px;
}

.finlo-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

* {
  margin: 0;
  padding: 0;
}
