.spinner-wrapper {
    height: 100%;
    width: 100%;
    z-index: 100000;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
}

.spinner-ball {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
}

.spinner-ball svg {
    color: rgb(0%, 20%, 125%);
}