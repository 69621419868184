.report_card {
  //   padding: 0.5rem;
  //   padding-left: 0;
  background-color: #3d797d !important;
  cursor: pointer;
  height: 100%;
  .notify-avatar {
    margin: 0.3rem;
    background: #1d5568;
  }
  .notify-avatar img {
    margin: 10px;
  }
  .message-tile {
    margin: 0.5rem;
    justify-content: center;
    row-gap: unset;
    width: unset;
    min-width: unset;
  }
  .notify-all-message {
    color: #fff;
    font-size: 16px;
  }
  .divider {
    border: none;
    height: auto;
    background-color: #1de497;
    width: 6px;
    visibility: hidden;
  }
  .show {
    visibility: visible;
  }
  .notify-tile {
    align-items: center;
    height: 100%;
    width: unset;
    column-gap: unset;
  }
}
.report-bar {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
}
.report-tile {
  flex-grow: 1;
}
