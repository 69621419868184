* {
  --Text-color: #204768;
  --Border-color: #cbcbcb;
}

div.formdiv>div.selectbrdred>div.ant-select-selection {
  border: 1px solid red !important;
}

.formdiv {
  /* display: inline-block; */
  width: 100%;
  text-align: left;
  position: relative;
  color: #797777;
  /* margin-bottom: 10px; */
}

.formdiv .labeltxt {
  /* color: #666666;
  display: block;
  font-size: 14px;
  font-weight: 100; */
  color: #1C74BC;
  font-size: 1rem;
  font-weight: 400;
  padding: 0;
}

.formdiv .brdrcls,
.cldInput {
  width: 100%;
  outline: 0;
  font-size: 18px;
  box-shadow:0 0 9.5px 0px rgb(211 235 255) !important;
  border-radius: 4px !important;
  border: 1px solid #1C74BC;
  color: #0c0c0c;
  /* margin: 5px; */
}

.formdiv .txtarea {
  width: 100%;
    padding: 4px 10px;
    outline: 0;
    font-size: 14px;
    box-shadow: 0 0 9.5px 0px #ccc !important;
    border-radius: 4px !important;
    border: 1px solid var(--Border-color);
    min-height: 30px;
    color: var(--Text-color);
}

textarea::placeholder {
  color: #737373 !important;
  opacity: 2;
}


.formdiv .brdred {
  border: 1px solid red;
}

.formdiv .selectbrdred .ant-select-selector {
  border: 1px solid red !important;
}

/* .formdiv .datePickerbrdred .MuiOutlinedInput-notchedOutline {
  border: 1px solid red !important;
} */

.formdiv .mailbrdred .MuiOutlinedInput-adornedEnd {
  border: 1px solid red !important;
}

.brdrcalender .imgRelative {
  border: 1px solid red !important;
  border-radius: 4px !important;
}

.formdiv .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  background-color: #c9c8c8;
}

.formdiv .brdnone .ant-select-selection {
  border: none !important;
}

.formdiv .MuiFormControl-root .MuiTextField-root .MuiFormControl-marginNormal {
  box-shadow: transparent;
}

.formdiv .radiobtncolor {
  color: #82af3f !important;
}

.formdiv .datepickerchnge input {
  width: 100% !important;
  padding: 20px;
  border-radius: 0px;
}

.formdiv .selectbox .ant-select-selection--single {
  height: unset !important;
  padding: 0px 8px !important;
  outline: 0;
  /* height: 30px; */
  box-shadow: 0 0 9.5px 0px #ccc !important;
  color: #797777;
  border-radius: 4px !important;
  border: 1px solid var(--Border-color) !important;
}

.formdiv .selectbox .ant-select-selection--single img{
  filter: invert(100%) sepia(19%) saturate(1628%) hue-rotate(165deg) brightness(94%) contrast(92%);
}

.formdiv .ant-select-disabled .ant-select-selection,
.formdiv .ant-select-disabled {
  background-color: #e0dbdba1;
  cursor: auto;
  color: black;
}

.formdiv .selectbox .ant-select-selector {
  padding-left: 5px;
  /* font-size: 13px; */
}

.formdiv .selectAdjustHeight .ant-select-selector {
  height: 30px;
}

.formdiv .ant-select-arrow {
  position: absolute;
  right: 10px;
  top: 40%;
}

.SelectInput_svg {
  width: 30px;
  position: relative;
  top: 2px;
  left: 20%;
}

.formdiv .selectbox {
  /*   z-index: 1400  !important;*/
  width: 100%;
  border-radius: 0;
  outline: unset;
  min-width: 8rem;

}

.formdiv .ant-select-selection {
  padding: 0px !important;
  outline: 0;
  height: auto;
  /* box-shadow: 0 0 1.5px 0px #ccc; */
  color: #797777;
  border-radius: 4px !important;
  border: 1px solid var(--Border-color) !important;
}

.formdiv .ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 0;
  margin-left: 0px;
  line-height: 30px;
}

.formdiv .ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: -3px !important;
  color: #b9b9b9 !important;
  font-size: 13px !important;
}

.formdiv .ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: black;
}

.formdiv .ant-select-search--inline .ant-select-search__field {
  border: none;
  box-shadow: none;
}

.formdiv .ant-select-search__field__wrap input {
  padding-left: 0px;
}

.formdiv .Errormsg {
  font-size: 12px;
  margin-top: 4px;
  color: red;
  /* height: 15px; */
}

/* .ant-select-dropdown {
    z-index: 1400;
  }
  .ant-calendar-picker {
    z-index: 1410;
  }
  .ant-calendar-picker-container {
    z-index: 1410 !important;
  } */

.formdiv .MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px;
  width: 100%;
  background: white;
  box-shadow: 0 0 10.5px 0px #ccc !important;
  /* border: 1px solid #d9d9d9; */
  border-radius: 3px;
}

.MuiButton-contained {
  box-shadow: unset !important;
}

/* for datepicker */

.MuiPickersCalendarHeader-dayLabel {
  font-size: 14px;
}

.MuiTypography-colorInherit {
  font-size: 13px;
}

.MuiPickersDay-daySelected {
  background-color: #204768 !important;
}

.MuiDialogActions-root.MuiDialogActions-spacing .MuiButton-label {
  font-size: 12px;
}

.MuiDialogActions-root.MuiDialogActions-spacing .MuiButton-label {
  color: #204768 !important;
}

.formdiv .MuiInputBase-inputAdornedEnd {
  color: var(--Text-color);
  padding: 7px;
  font-size: 14px;
  z-index: 1;
  padding: 8px;
}

.formdiv .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--Border-color);
  background-color: #fff;
  height: 38px;
    background-color: unset;
    border: none;
}
.datePickerbrdred .MuiInputBase-formControl{
  border: 1px solid red !important;
}
.formdiv p{
  position: absolute;
  top: 40px;
  font-size: 12px;
  margin: 0px;
  color: red !important;
  font-family: 'Nunito Regular';
}

.formdiv .MuiOutlinedInput-notchedOutline legend{
  background-color: unset;
}

.formdiv .MuiFormControl-marginNormal button,
.formdiv .MuiFormControl-marginNormal path {
  color: var(--Text-color);
  z-index: 1;
  padding: 0px;
  /* margin: 7px; */
}

.formdiv .MuiFormControl-marginNormal button svg { font-size: 20px; }

.formdiv .MuiOutlinedInput-adornedEnd {
  padding: 0%;
  border-radius: 4px;
  /* border: 1px solid var(--Border-color); */
  /* height: 38px !important; */
}

.formdiv .ant-select-selection-placeholder {
  color: #b9b9b9 !important;
}

.inputlabel ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b9b9b9 !important;
  opacity: 1;
  /*Firefox*/

}

.ant-select-open .SelectInput_svg {
  transform: rotate(180deg);
  transition: 0.3s;
  margin-left: -2px;
  margin-top: 1px;
}

.formdiv .ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 5px;
}

/* datePicker header */

.MuiPickersToolbar-toolbar {
  height: auto !important;
  background-color: #204768 !important;
}

.MuiPickersToolbar-toolbar h4 {
  font-size: 20px !important;
}

/* time Picker */

.labelboxTimePicker {
  width: 17px;
}

/* dropdown color */

.formdiv .ant-select-selection-selected-value {
  font-size: 16px !important;
  color: var(--Text-color);
  font-weight: 400;
  margin-left: -3px !important;
  /* padding-right: 35px !important; */
}

.ant-select-dropdown ul {
  padding: 0% !important;
}

.ant-select-dropdown-menu-item {
  color: rgb(102, 98, 98);
}

.inputID::placeholder {
  color: black !important;
  opacity: 1;
}

/* .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--Border-color) !important;
} */

.MuiInputAdornment-root {
  z-index: 99;
  position: relative;
  right: 15px;
}

.formdiv .MuiInputBase-inputAdornedEnd {
  font-size: 14px;
}

input[type="radio" i] {
  transform: scale(2);
}

.ant-radio-group {
  width: 100% !important;
  display: flex !important;
  justify-content: space-around !important;
  padding: 5px;
  border-radius: 4px;
}

.ant-radio-inner {
  width: 20px !important;
  height: 20px !important;
}

.ant-radio-inner::after {
  top: 4px !important;
  left: 4px !important;
  width: 10px !important;
  height: 10px !important;
}

span.ant-radio+* {
  padding-right: 8px !important;
  padding-left: 8px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  /* color: darkgrey; */
}

/* .ant-radio{margin: 8px;} */
/* .MuiCheckbox-colorSecondary.Mui-checked:hover {
  color: var(--Text-color) !important;
} */

.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--Text-color) !important;
}

/* .MuiIconButton-colorSecondary:hover {
  background-color: #20476814 !important;
} */

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #707070 !important;
}

.check {
  display: flex;
  color: var(--Text-color);
}

.check .MuiCheckbox-root {
  padding: 0;
  padding-right: 0.3rem;
}

.check label {
  padding: 0px;
  margin: 0px 0px 0px 4px;
  font-size: 14px;
}
.calender_div {
  width: 100%;
  border: 2px solid var(--Border-color);
  border-radius: 4px;
  padding: 7px;
  display: flex;
  justify-content: flex-end;
}

.calender_div div {
  width: 288px;
}

.d-none {
  display: none;
}

.inputRelative {
  /* position: relative;
    display: inline-flex;
    justify-content: center; */
  margin: 3% 0%;
  width: 100%;
  cursor: pointer;
  /* noneed height when use this to your project */
  /* height: 20rem; */
  /*  */
}

.cldRangeInput {
  padding: 3px 20px 3px 10px !important;
}

/* .cldInput,
  .cldRangeInput {
    width: 100%;
    height: 22px;
    border: 1px solid #969696;
    border-radius: 3px;
    outline: none;
    color: transparent;
    text-shadow: 0 0 0 #545454;
    cursor: pointer;
    font-size: 14px;
    padding: 3px 2px 3px 4px;
  } */

.imgRangeAbsolute {
  position: absolute;
  top: 5.5px;
  right: -27px;
  width: 20px;
  cursor: pointer;
}

.imgRangeRelative {
  position: relative;
  right: 15px;
}

.cldAbsolute {
  position: absolute;
  top: 50px;
  filter: drop-shadow(0px 0px 3px #607d8b);
  z-index: 100;
}

.cldAbsolute:after {
  content: " ";
  position: absolute;
  margin-left: -8px;
  bottom: 100%;
  left: 50%;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
}

.imgRelative {
  position: relative;
  width: 100%;
}

.imgAbsolute {
  position: absolute;
  top: 8.5px;
  right: 12px;
  width: 18px;
  cursor: pointer;
  background: white;
}

.rsd_container .cld_container {
  box-shadow: none;
  width: 20em;
  min-width: 15em;
  padding: 2px;
}

.rsd_container .cld_btnAlign {
  padding: 5px 0 0 0;
}

.rsd_container .cld_btnAlign button {
  box-shadow: none;
  background-color: transparent;
}

.rsd_container th,
.rsd_container td {
  font-size: 12px;
  padding: 7px 0;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  border-radius: 20px !important;
  background-color: var(--Warning-color);
}

.ant-select-selection__choice__content {
  color: white !important;
}

.ant-select-selection__choice__remove {
  color: red !important;
}

.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  border-radius: 20px !important;
  background-color: var(--Warning-color);
}

.ant-select-selection--multiple .ant-select-selection__rendered {
  margin: 0 !important;
  border: 1px solid var(--Border-color);
  height: 38px;
  padding: 2px;
}

.rmdp-container {
  width: 100%;
  margin-top: 6px;
}

.rmdp-input {
  width: 100%;
  line-height: 1.1876em;
  height: 38px !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type=file] {
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #555;
  height: auto;
  font-size: 12px
}

.upload-btn-wrapper input[type=file]::file-selector-button {
  margin-right: 1rem;
  border: none;
  background: var(--Primary-color);
  padding: 1rem;
  border-radius: 14px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

.upload-btn-wrapper input[type=file]::file-selector-button:hover {
  background: #0d45a5;
}

.date-icon .MuiSvgIcon-root{
    margin-right: -10;
    color: '#33465C';
    z-index: 99;
}
.date-input input {
    z-index: 99;
}

.asterisk {
  color: var(--Danger-color);
  font-size: 14px;
  font-weight: bold;
  padding-left: 2px;
}

.password-icon{
  position: absolute;
    top: 30px;
    right: 10px;
    cursor: pointer;
}

.label-read-only{
    color: var(--Text-color);
}

.inputID::-webkit-input-placeholder { /* Edge */
  opacity: 0.5;
}

.inputID:-ms-input-placeholder { /* Internet Explorer 10-11 */
  opacity: 0.5;
}

.inputID::placeholder {
  opacity: 0.5;
}
.inputID:disabled {
  background-color: #e0dbdba1;
}
input[type="checkbox"]{
  width: 18px;
  appearance: none;
  vertical-align: middle;
  border-radius: 3px;
  height: 18px;
  border: 3px solid rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
input[type='checkbox']:checked {
  background-color: var(--Text-color);
  position: relative;
    border: none;
}
input[type='checkbox']:checked:before {
  font-size: 13px;
  position: absolute;
  top: 1px;
  right: 4px;
  color:white;
  content: "\2714\0020";
}
.customDateInput{
  position: absolute;
  z-index: 99;
  border: 0;
  width: 80%;
  color: var(--Text-color);
  font-size: 14px;
  padding: 8px;
}