.tableHead .MuiButtonBase-root.MuiTableSortLabel-root,
.tableHeadsno {
  color: #ffffff !important;
  font-weight: 500 !important;
  font-size: 16px;
  display: block;
  text-align: left; //Left align header text
}

.MuiTableCell-alignLeft {
  text-align: left !important; //Left align body text
}

.tableHead .MuiTableCell-head {
  color: #fff !important;
  font-weight: 600 !important;
  background-color: var(--Text-color);
}

.MuiTableSortLabel-iconDirectionAsc {
  display: none !important;
}

.tableTitle {
  display: flex;
  padding: 2px 5px;
  font-weight: 800;
  font-size: 16px;
  align-items: center;
}

.MuiTableCell-sizeSmall {
  padding: 8px 8px 6px 6px !important;
}

.nodatafound img {
  width: 100px;
  margin-top: 40px;
}

.nodatafound.MuiTableCell-sizeSmall {
  text-align: center !important;
}

.nodatatext {
  margin-top: 20px;
}

.MuiTableCell-body {
  color: var(--Text-color) !important;
}

.align-cell-top {
  vertical-align: top !important;
}

// .MuiTableRow-root.Mui-selected:hover {
//     background-color: #d8d1d1 !important;
// }

// .MuiTableRow-root.Mui-selected {
//     background-color: var(--Border-color);
// }

.MuiTableBody-root tr:nth-child(odd) {
  background-color: white !important;
}

.MuiTableBody-root tr:nth-child(even) {
  background-color: #ffe6c5 !important;
}

.tableIconDiv {
  display: flex;
  align-items: center;
  padding: 7px 0px 3px 0px;
  width: 100%;
}

.tableIcon {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.tableIcon img {
  width: 60% !important;
}

.Title_card {
  color: var(--Text-color);
  font-size: 1.3rem;
  font-weight: 600;
}

.full_width_div {
  width: 100%;
}

.row-action {
  display: flex;
  justify-content: flex-start;
  column-gap: 0.5rem;
  
  .paymentStatus svg {
    color: var(--Primary-color);
    cursor: pointer;
  }
  .update svg {
    color: var(--Primary-color);
    cursor: pointer;
  }

  .edit svg {
    color: var(--Blue-color);
    cursor: pointer;
  }

  .delete svg {
    color: var(--Danger-color);
    cursor: pointer;
  }

  .print svg {
    color: midnightblue;
    cursor: pointer;
  }
  .email svg {
    color: #1355AD;
    cursor: pointer;
  }
  .download svg {
    color: #1355AD;
    cursor: pointer;
  }
  .file img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .done svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
     .close svg {
       width: 20px;
       height: 20px;
       cursor: pointer;
     }
  .undo img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    filter: invert(15%) sepia(54%) saturate(2669%) hue-rotate(220deg)
      brightness(86%) contrast(101%);
  }
}

.search_box {
  width: 100%;
  padding: 4px;
}
.search_box .custom_labelbox {
  width: fit-content;
}
.search_box .custom_labelbox .formdiv .MuiFormControl-marginNormal {
  border-radius: 4px;
}

.search_box .custom_labelbox fieldset {
  border: 0;
  top: 0;
}
.action-elements {
  width: 100%;
  display: flex;
  // justify-content: flex-end;
  padding: 0.4rem;
}
.do-not-disturb svg {
  color: var(--Danger-color);
}
.show {
  // visibility: visible;
  display: block;
}

.hide {
  // visibility: hidden;
  display: none;
}

.convert {
  width: 1.2rem;
  cursor: pointer;
  img {
    filter: invert(25%) sepia(8%) saturate(3733%) hue-rotate(166deg)
      brightness(94%) contrast(95%);
  }
}
