/*--------Variable for font css styles--------*/
*{ --Primary-color: #0067b8;
  --Success-color:#2FA84F;
  --Text-color:#1355AD;
  --Warning-color:#f27309;
  --Update-color:#FDAC41;
  --Blue-color:#568FE3;
  --Yellow-color:#FFBE0D;
  --Theme-color:beige;
  --Danger-color:#E41F3A;
  --MistyBlue-color:#AFC1D0;
  font-family: "Nunito Regular";
}

@font-face {
  font-family: "Nunito Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./resources/fonts/Nunito-Regular.ttf") format("truetype");
}

body{
  margin:0;
  overflow: hidden;
}

::placeholder{opacity: 0.5;}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--Warning-color); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--Warning-color); 
}

/* cursor */
.cursor {
  cursor: pointer;
}

/* card */
.shadow-card{
  box-shadow: 0px 2px 8px 2px rgb(0, 0, 0, 10%);
  border-radius: 4px;
  padding: 0.3rem;
}

.hbox {
  display: flex;
    flex-direction: row;
    column-gap: 1.5em;
    padding: 0.5rem 0.8rem;
}
.pdf-framebox {
  background: var(--Border-color) !important;
}
.pdf-header{
  padding: 15px 4px 30px 4px;
}