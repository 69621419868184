.TitlePane {
  font-size: 2em;
  font-weight: 500;
  color: #f27309;
  // padding-bottom: 10px;
}
.ques_bank_inputs {
  width: 70%;
}
.heading {
  color: darkgrey;
  font-size: 20px;
  font-weight: 400;
  // padding-left: 2%;
}
.ques_bank_inputs .ant-radio-group {
  justify-content: space-between !important;
}
.btn_css_9 {
  display: flex;
  width: 44%;
  justify-content: flex-end;
  margin-bottom: auto;
}
.btn_css_12 {
  display: flex;
  width: 33%;
  justify-content: flex-end;
}
.actionicon {
  cursor: pointer;
  padding: 0px 5px;
}
.cc_none {
  color: var(--Text-color);
}
.input-div {
  border-bottom: 2px dashed var(--Border-color);
  width: 100%;
  margin: 20px 0px;
}
.input-row {
  padding-bottom: 20px !important;
}
.link-empty {
  color: var(--Text-color);
  font-weight: bold;
}
