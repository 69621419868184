.toolBox {
  border-radius: 4px;
  padding: 0.5rem;
  background: white;
  // width: max-content;

  tr td {
    padding: 5px;
  }
}

.toolDescription {
  font-weight: 600;
}

.toolBox .outline {
  border: 1px solid #9f9ea0;
}
