.notify-avatar {
  background: var(--Warning-color);
  img {
    width: 60%;
    height: 60%;
    filter: brightness(0) invert(1);
  }
}
.notify-tile {
  display: flex;
  column-gap: 10px;
  width: 100%;
}
.message-tile {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  min-width: 16rem;
}
.notify-message {
  max-width: 16rem;
  white-space: pre-wrap;
  font-weight: 600;
  font-size: 12px;
  font-style: italic;
}
.notify-time {
  font-size: x-small;
  color: darkgrey;
}

.unread::before {
  display: inline-block;
  content: "";
  -webkit-border-radius: 0.375rem;
  border-radius: 0.375rem;
  height: 0.5rem;
  width: 0.5rem;
  background-color: rgb(92, 128, 226);
}
.notify-unread-menuitem {
  background-color: #b6d1e7a8 !important;
}
.notify-see-all {
  text-align: center;
  color: var(--Blue-color);
  font-weight: 500;
  cursor: pointer;
}
.no-notify {
  color: var(--Danger-color);
}
.notify-read-menuitem {
  opacity: 0.5;
  background-color: #e0e0e0 !important;
}
.notify-unread-date {
  color: var(--toastify-color-info);
}
.notify-unread-msg {
  color: var(--Primary-color);
}
.notify-border {
  border-bottom: 1px dashed #8888e8 !important;
}
