.notify-all-paper {
  padding: 1rem 2rem;
  //   max-height: 100%;
  li {
    pointer-events: none;
  }
}
.notify-all-label {
  font-size: 2em;
  color: var(--Primary-color);
}
.notify-all-message {
  max-width: 100%;
}
.notify-tiles {
  height: auto;
  overflow-y: auto;
}
