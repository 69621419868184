.transparent {
  background: transparent !important;
}
.transparent:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.danger {
  background: var(--Danger-color) !important;
}
.danger:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.primary {
  background: var(--Primary-color) !important;
}
.primary:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.secondary {
  background: var(--Secondary-color) !important;
}
.secondary:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.teritory {
  background: var(--Warning-color) !important;
}
.teritory:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.success {
  background: var(--Success-color) !important;
}
