.payment-notes ul li {
  list-style-position: inside;
}
.payment-notes {
  padding: 0.5rem;
  background: beige !important;
}
.payment-notes-msg {
  color: var(--Warning-color);
  font-weight: 600;
}
.button {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

.MuiButton-root.Mui-disabled {
  cursor: not-allowed !important;
    opacity: 0.6 !important; // Make it look disabled
    pointer-events: none !important; // Prevent click events
}